/* eslint-disable indent */
/* eslint-disable comma-dangle */
/* eslint-disable no-trailing-spaces */

type StaticImages = {
    readonly [fileName: string]: {
        uri?: string;
    };
}

const imageStaticSourcesByFileName: StaticImages = {
    '4gQ7c3dR8TYms2C3CNxYE4.png': { uri: '/images/4gQ7c3dR8TYms2C3CNxYE4.png' },
'1hfcx1fktycpnAG2kchUZf.png': { uri: '/images/1hfcx1fktycpnAG2kchUZf.png' },
'gHULytamH4ki2zoZHp5Lca.png': { uri: '/images/gHULytamH4ki2zoZHp5Lca.png' },
'quXid86D5pjRciT4Kn4f6R.png': { uri: '/images/quXid86D5pjRciT4Kn4f6R.png' },
'utvXcQAYr1unug4Pxdd97k.png': { uri: '/images/utvXcQAYr1unug4Pxdd97k.png' },
'nL1iW9CezmGugo6z7Rtahi.png': { uri: '/images/nL1iW9CezmGugo6z7Rtahi.png' },
'77f5jGQmCj2mA2WCqsP8Si.png': { uri: '/images/77f5jGQmCj2mA2WCqsP8Si.png' },
'oNptFoGoDhG6SrYRzvusnQ.png': { uri: '/images/oNptFoGoDhG6SrYRzvusnQ.png' },
'cZaL8v6unJapWVhggrDvZ1.png': { uri: '/images/cZaL8v6unJapWVhggrDvZ1.png' },
's3XJ6QuZ8XNddTBPAwsCcg.png': { uri: '/images/s3XJ6QuZ8XNddTBPAwsCcg.png' },
'oC3ppFnBStEhyvdny81uBy.png': { uri: '/images/oC3ppFnBStEhyvdny81uBy.png' },
'fb75t56iGL98nMo79Sgcru.png': { uri: '/images/fb75t56iGL98nMo79Sgcru.png' },
'3csSWJMwN1sFfpCTFyGrNo.jpg': { uri: '/images/3csSWJMwN1sFfpCTFyGrNo.jpg' },
'6eisDqL5ZogVuVd13b8MSG.jpg': { uri: '/images/6eisDqL5ZogVuVd13b8MSG.jpg' },
'9ETeyBArzUezPp5bHLZh5B.jpg': { uri: '/images/9ETeyBArzUezPp5bHLZh5B.jpg' },
'82eRdqeEjG9DWazmFcSXtk.png': { uri: '/images/82eRdqeEjG9DWazmFcSXtk.png' },
'dvK8LtZaotSKwDWkPhHopb.jpg': { uri: '/images/dvK8LtZaotSKwDWkPhHopb.jpg' },
'tVEH4ysRoSy7YZHBuNVnJL.png': { uri: '/images/tVEH4ysRoSy7YZHBuNVnJL.png' },
'6aXnHc2gEv8LrTGjaeRuif.jpg': { uri: '/images/6aXnHc2gEv8LrTGjaeRuif.jpg' }
}

export default imageStaticSourcesByFileName
